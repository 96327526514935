import React from 'react';
import { graphql } from 'gatsby';
import { PageWrapper } from '../components/Layout/PageWrapper';
import Container from '../components/Layout/SharedStyles/Container';
import PageHeading from '../components/Layout/PageHeading/PageHeading';
import Locations from '../components/Locations/Locations';
import ContentwithButton from '../components/ContentwithButton/ContentWithButton';

const DealerPages = ({
  data: {
    datoCmsDealerPage: {
      locale,
      title,
      id,
      metaTags,
      note,
      contentText,
      contentLink,
      subHeading,
    },
    homeCrumb,
  },
  pageContext,
}) => {
  const breadcrumbs = [
    { title: homeCrumb.title, id: homeCrumb.id },
    { title: title, id: id },
  ];
  return (
    <PageWrapper
      breadcrumbs={breadcrumbs}
      pageData={pageContext}
      metaTags={metaTags}
    >
      <PageHeading heading={title} subHeading={subHeading} />
      <ContentwithButton text={contentText} link={contentLink} />
      <Container width={'large'} pt={2} pb={2}>
        <Locations locale={locale} />
      </Container>
    </PageWrapper>
  );
};

export default DealerPages;

export const query = graphql`
  query DealerPageQuery($locale: String!) {
    datoCmsDealerPage(locale: { eq: $locale }) {
      locale
      title
      metaTags {
        title
        description
        image {
          url
        }
      }
      id: originalId
      subHeading
      note
      contentText
      contentLink {
        ...Link
      }
    }
    homeCrumb: datoCmsHomepage(locale: { eq: $locale }) {
      locale
      title
      id: originalId
    }
  }
`;
