import React from 'react';
import Container from '../Layout/SharedStyles/Container';
import Button from '../Button/Button';
import style from './content-with-button.mod.scss';

const ContentwithButton = ({ text, link }) => {
  if (text || link?.length > 0) {
    return (
      <Container pb={1} pt={1} width={'small'}>
        <div className={style.content_button}>
          {text && <div dangerouslySetInnerHTML={{__html: text}} />}
          {link.length > 0 && (
            <Button primary size={'small'} recordId={link[0]?.page?.id}>
              {link[0]?.text}
            </Button>
          )}
        </div>
      </Container>
    );
  }
  return null
};

export default ContentwithButton;
