import React from 'react';
import { Script } from 'gatsby';

const Locations = ({ locale }) => {
  const isBrowser = () => typeof window !== 'undefined' && window;
  const filterOptions = [
    {locale: 'en-NZ', id: '100194'},
    {locale: 'en-AU', id: '100219'},
    {locale: 'en-EU', id: '100220'},
    {locale: 'en-GB', id: '100195'},
    {locale: 'en-FR', id: '114387'},
    {locale: 'en-DE', id: '110514'},
    {locale: 'en-BE', id: '110588'},
    {locale: 'en-NL', id: '110589'},
    {locale: 'en-CN', id: '110609'},
    {locale: 'fr-FR', id: '114387'},
  ];

  const filter = filterOptions.find((option) => option.locale === locale)

  return (
    <>
      <div>
        <div class="storerocket-store-locator"></div>
        <Script
          src="//cdn.storerocket.io/widget.js"
          onLoad={() =>
            isBrowser &&
            window.StoreRocket.init({
              selector: '.storerocket-store-locator',
              account: 'ezpBv3Npvy',
              filters: filter.id,
            })
          }
          onError={() => console.log('sadness')}
        />
      </div>
    </>
  );
};

export default Locations;
